const skills = [
  "3D Art",
  "3D Modelling",
  "3D Motion",
  "Advertising",
  "Animation",
  "App Design",
  "AR/VR",
  "Architecture",
  "Art Direction",
  "Automotive Design",
  "Beauty Photography",
  "Branding",
  "Calligraphy",
  "CGI",
  "Character Design",
  "Cinematography",
  "Collage",
  "Coloring",
  "Comic",
  "Concept Art",
  "Copywriting",
  "Costume Design",
  "Crafts",
  "Creative Direction",
  "Digital Art",
  "Digital Painting",
  "Drawing",
  "Editing",
  "Editorial Design",
  "Environmental Graphics",
  "Exhibition Design",
  "Fashion Design",
  "Film",
  "Fine Arts",
  "Game Design",
  "GIF Animation",
  "Graphic Design",
  "Icon Design",
  "Illustration",
  "Industrial Design",
  "Infographic",
  "Information Architecture",
  "Interaction Design",
  "Label Design",
  "Logo Design",
  "Modelling",
  "Motion Graphics",
  "Packaging",
  "Painting",
  "Paperworks",
  "Pattern Design",
  "Performing Arts",
  "Photography",
  "Poster Design",
  "Product Design",
  "Projection Mapping",
  "Props Design",
  "Retouching",
  "Sketching",
  "Storyboarding",
  "Styleframing",
  "UI/UX",
  "Visual Effects",
  "Visualization",
  "Web Design",
];
export default skills;
